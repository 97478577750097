// ./src/App.js
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

function App() {
  console.log('App rendering...');

  return (
    <div className="App">
      <Header />
      <Router>
        <Switch>
          {console.log('Rendering Home page')}
          <ScrollToTopRoute exact path="/" component={Home} />

          {console.log('Rendering Privacy page')}
          <Route exact path="/privacy" component={Privacy} />

          {console.log('Rendering Terms page')}
          <Route exact path="/terms" component={Terms} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
