import React from 'react'

function KatanaArea() {
  return (
	 <section className="why-katana-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center mb-60">
                <h2 className="title">Why Pet Legends?</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/img/icon/pet-legends-icon02.png" alt="" />
                </div>
                <div className="features-content">
                  <h4 className="title">Mobile</h4>
                  <p>We are building a game that will bring millions of people into Web3 through the power of Pet Community Tokens. We are starting on Solana as it is fast, has low fees and allows for microtransactions.</p>
                </div>
                <span className="features-line" />
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/img/icon/pet-legends-icon01.png" alt="" />
                </div>
                <div className="features-content">
                  <h4 className="title">FUN GAMEPLAY</h4>
                  <p>We are building fun all ages MMO gameplay, similar to Roblox, where players from all ages can participate in multiplayer mobile gameplay from anywhere on Earth!</p>
                </div>
                <span className="features-line" />
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="features-item">
                <div className="features-icon">
                  <img src="assets/img/icon/pet-legends-icon03.png" alt="" />
                </div>
                <div className="features-content">
                  <h4 className="title">pet community tokens</h4>
                  <p>We partner with Pet Community Tokens, like BONK and SAMO to create demand and utility through fun gameplay experiences. We have integrated the latest wallets, swaps, and token specific quests to earn and spend your favourite token.</p>
                </div>
                <span className="features-line" />
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default KatanaArea