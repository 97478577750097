import React from 'react'


function Brand() {
  return (
	<div className="brand-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="brand-item-wrap">
                <ul>
                  <li><a href="https://www.magicblock.gg/" target="_blank"><img src="assets/img/images/magicblock-logo.svg" width="275" alt="" /></a></li>
                  <li><a href="https://solana.com/" target="_blank"><img src="assets/img/images/solana-logo.svg" width="225" alt="" /></a></li>
                  <li><a href="https://solana.com/news/solana-grizzlython-winners" target="_blank"><img src="assets/img/images/grizzlython_logo.png" alt="" /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Brand