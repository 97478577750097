// ./pages/Privacy.jsx
import React from 'react';

const Privacy = () => {
  return (
    <div style={{ paddingTop: '7em' }}>
      {/* <Meta title="Privacy Policy || Pet Legends " /> */}
      <div className="pt-24">
        <section className="dark:bg-jacarta-800 relative py-16 md:py-24">
          {/* <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <CustomImage src="/images/gradient_light.jpg" alt="gradient" className="h-full w-full" />
          </picture> */}
          <div className="container">
            <h1 className="font-display text-jacarta-700 text-center text-4xl font-medium dark:text-white">
              Privacy Policy
            </h1>
            <div className="article-content mx-auto max-w-[48.125rem]">
              <h2 className="text-base">Introduction</h2>
              <p>
                This Privacy Policy describes how Pet Legends collects, uses, and discloses information, and what choices you have with respect to the information.
              </p>
              <h2>Information We Collect</h2>
              <p>
                When you interact with our services, we collect information that you provide to us directly and information that we collect automatically.
              </p>
              <h3>Information You Provide to Us</h3>
              <p>
                We collect information that you provide to us directly when you create an account, update your profile, participate in any interactive features of the Services, fill out a form, request customer support, or otherwise communicate with us. The types of information we may collect include your name, email address, profile information, and any other information you choose to provide.
              </p>
              <h3>Information We Collect Automatically</h3>
              <p>
                When you use our Services, we automatically collect information about you, including:
              </p>
              <ul>
                <li><strong>Usage Information:</strong> We collect information about your interactions with the Services, such as the pages or content you view, your searches, and other actions.</li>
                <li><strong>Log Information:</strong> We collect log information about your use of the Services, including the type of browser you use, access times, pages viewed, your IP address, and the page you visited before navigating to our Services.</li>
                <li><strong>Device Information:</strong> We collect information about the computer or mobile device you use to access our Services, including the hardware model, operating system and version, unique device identifiers, and mobile network information.</li>
                <li><strong>Location Information:</strong> We may collect information about the location of your device each time you use our app or otherwise consent to the collection of this information.</li>
                <li><strong>Cookies and Similar Technologies:</strong> We use cookies and other similar technologies to collect information about your usage and device.</li>
              </ul>

              <h2>How We Use Information</h2>
              <p>
                We use the information we collect to provide, maintain, and improve our Services, such as to:
              </p>
              <ul>
                <li>Provide and deliver the services you request, process transactions and send you related information, including purchase confirmations and invoices.</li>
                <li>Send you technical notices, updates, security alerts, and support and administrative messages.</li>
                <li>Respond to your comments, questions, and requests, and provide customer service.</li>
                <li>Communicate with you about products, services, offers, promotions, rewards, and events offered by Pet Legends and others, and provide news and information we think will be of interest to you.</li>
                <li>Monitor and analyze trends, usage, and activities in connection with our Services.</li>
                <li>Detect, investigate, and prevent fraudulent transactions and other illegal activities, and protect the rights and property of Pet Legends and others.</li>
                <li>Personalize and improve the Services, and provide advertisements, content, or features that match user profiles or interests.</li>
              </ul>

              <h2>NFTs and Memecoins</h2>
              <p>
                Our Services include interactions with NFTs and memecoins. Information related to these interactions may be collected and used as described in this policy, including:
              </p>
              <ul>
                <li>Details about NFTs and memecoins you hold, buy, sell, or transfer.</li>
                <li>Transaction data on public blockchains.</li>
                <li>Information necessary to facilitate quests and rewards in memecoins.</li>
              </ul>

              <h2>Sharing of Information</h2>
              <p>
                We may share information about you as follows or as otherwise described in this Privacy Policy:
              </p>
              <ul>
                <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</li>
                <li>In response to a request for information if we believe disclosure is in accordance with, or required by, any applicable law, regulation, or legal process.</li>
                <li>If we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, and safety of Pet Legends or others.</li>
                <li>With your consent or at your direction.</li>
                <li>We may also share aggregated or de-identified information, which cannot reasonably be used to identify you.</li>
              </ul>

              <h2>Your Choices</h2>
              <p>
                <strong>Account Information:</strong> You may update, correct, or delete information about you at any time by logging into your account or contacting us. If you wish to deactivate your account, please contact us, but note that we may retain certain information as required by law or for legitimate business purposes.
              </p>
              <p>
                <strong>Cookies:</strong> Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove or reject browser cookies. Please note that if you choose to remove or reject cookies, this could affect the availability and functionality of our Services.
              </p>
              <p>
                <strong>Promotional Communications:</strong> You may opt out of receiving promotional emails from Pet Legends by following the instructions in those emails or by contacting us. If you opt out, we may still send you non-promotional emails, such as those about your account or our ongoing business relations.
              </p>

              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, please <a href="/#contact">Contact Us</a>.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Privacy;
