import React, { useState, useEffect } from 'react';

function AboutArea() {
  const wordImageMap = {
    "SDOGE": "assets/img/images/pet-legends-triangle-sdoge.png",
    "BONK": "assets/img/images/pet-legends-triangle-bonk.png",
    "SAMO": "assets/img/images/pet-legends-triangle-samo.png",
    "KIN": "assets/img/images/pet-legends-triangle-kin.png"
  };

  const words = Object.keys(wordImageMap);
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [currentImage, setCurrentImage] = useState(wordImageMap[words[0]]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % words.length;
        setCurrentWord(words[nextIndex]);
        setCurrentImage(wordImageMap[words[nextIndex]]);
        return nextIndex;
      });
    }, 800);

    return () => clearInterval(intervalId);
  }, []);
  
  return (
	<section id="collect" className="about-area about-bg">
        <div className="container">
          <div className="s-about-wrap">
            <div className="row align-items-center">
              <div className="col-xl-7 col-lg-6">
                <div className="s-about-title">
                  <h2 className="title">PLAY, EARN, SPEND, SUMMON <span>{currentWord}</span></h2>
                </div>
                <div className="s-about-content">
                  <p>Gaming is our passion. We believe it will onboard the next billion users to Web3. We are partnering with Pet Community Tokens to create utility & demand. We currently support Quest earns and item spends for KIN tokens in game, with BONK, SAMO & SDOGE coming soon. You can also summon your 3D battle pet to fight alongside you in game as you fight against evil hordes of Skelly's. PvP Coop and PvP Arena modes coming soon!</p>
                </div>
              </div>
              <div className="col-xl-5 col-lg-6">
                <div className="s-about-img">
                  <img src={currentImage} alt={currentWord} />
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-6">
              <div className="about-title">
                <h2 className="title">COLLECTIBLES</h2>
                <div className="about-content">
                  <h6 className="rotate-content">Pet Legends NFT's</h6>
                  <h2 className="title">Playable <span>Characters</span></h2>
                  <p>There have been 420 OG NFT's minted with all to be given away to early community contributors. Holding these NFT's allow access to a special Simpl3r Verified Access "OG Lounge" channel on Discord, along with other cool surprises!”
                   <a href="/#" target="_blank" rel="noreferrer"> </a> 
                   All Pet Legends NFT's have special trait attributes that are interpreted by the Pet Legends game in unique ways.</p>
                  
                    <h4 className="small-title">TOTAL SUPPLY</h4>
                    <h6 className="price"> <span> 420 </span> / MAX</h6>
                </div>
                  
                <div className="about-btn-wrap">
                 
                  <a href="https://magiceden.io/creators/petlegends" className="btn" target="_blank">magic eden</a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6">
              <div className="about-img-wrap">
                <img src="assets/img/images/gamerplex-og.gif" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default AboutArea