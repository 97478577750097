import React from 'react'

function Footer() {
  return (
	<footer>
        <div className="footer-top-wrap">
          <div className="container footer-container">
            <div className="footer-menu-wrap">
              <div className="row align-items-center">
                <div className="col-lg-3">
                  <div className="f-logo">
                    <a href="/#"><img src="assets/img/logo/logo.png" alt="" /></a>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="footer-menu">
                    <nav>
                      <ul className="navigation">
                        <li><a href="/">PLAY</a></li>
                        <li><a href="/#collect">COLLECT</a></li>
                        <li><a href="/#learn">LEARN</a></li>
                        <li><a href="/#roadmap">ROADMAP</a></li>
                        <li><a href="/#contact">CONTACT</a></li>
                      </ul>
                      <div className="footer-search">
                        <form>
                          <input type="text" placeholder="Find Here Now" />
                          <button><i className="fa-solid fa-magnifying-glass" /></button>
                        </form>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-quick-link-wrap">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-8">
                  <div className="quick-link-list">
                    <ul>
                      <li><a href="/">Home</a></li>
                      <li><a href="/terms">Terms of Use</a></li>
                      <li><a href="/privacy">Privacy</a></li>
                      <li><a href="https://discord.com/invite/NbTu5FmQUN" target="_blank">Support</a></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-5 col-md-4">
                  <div className="footer-social">
                    <ul>
                    <li>
                    <a target="_blank" rel="noreferrer" href="https://x.com/pet_legends">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://discord.com/invite/NbTu5FmQUN">
                      <i className="fab fa-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://t.me/pet_legends">
                      <i className="fab fa-telegram"></i>
                    </a>
                  </li>
                </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="copyright-text text-center">
                  <p>Copyright © 2024 All Rights Reserved By <span>pet legends</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer
