import React from 'react'
import Helmet from "react-helmet";

function Contact() {
  return (
    <section id="contact" className="contact-area pt-115 pb-120">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title text-center mb-60">
            <h2 className="title">contact <span>pet legends</span></h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5">
          <div className="contact-content">
            <h4 className="title">Any <span>questions?</span></h4>
            <p>Reach out to us and we will get back to you shortly.</p>
            <ul className="contact-info-list">
              <li>
                <div className="icon"><i className="fa-solid fa-paper-plane" /></div>
                <div className="content">
                  <p><a href="https://t.me/pet_legends" target="_blank">Join us on Telegram &gt;</a></p>
                </div>
              </li>
              <li>
                <div className="icon"><i className="fa-solid fa-discord" /></div>
                <div className="content">
                  <p><a href="https://discord.com/invite/NbTu5FmQUN" target="_blank">Join us on Discord</a></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-7">
            <div className="contact-form">
              <iframe src="https://embeds.beehiiv.com/4bd68845-b450-46a1-a3e9-af903fd93e93" data-test-id="beehiiv-embed" width="480" height="320" frameborder="0" scrolling="no" style={{ borderRadius: '4px', border: '2px solid #FDCF71', margin: 0, backgroundColor: 'transparent' }}></iframe>
            </div>
          </div>
        </div>
      </div>
  </section>
  )
}

export default Contact